import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import ProjectLink from "../components/ProjectLink"

const ThemePage = ({ data }) => {
  const { themeQuery, projectsQuery } = data
  const theme = themeQuery["frontmatter"]
  const projects = projectsQuery["edges"]

  const imageStyle = { borderRadius: "10px", maxWidth: 300 }

  return (
    <Layout>
      <div className="text-3xl my-6 font-bold">Theme: {theme.name}</div>
      <Img style={imageStyle} fluid={theme.image.childImageSharp.fluid} />
      <div className="text-xl my-6 font-bold">Projects: </div>
      <div className= "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {projects &&
          projects.map((project, i) => (
            <ProjectLink key={i} project={project.node} />
          ))
        }
      </div>
    </Layout>
  )
}

export default ThemePage

// String! implies non-nullable
// We are guaranteed an id because once was assigned on page creation
export const pageQuery = graphql`
  query ThemeByID($id: String!, $projects: [String]) {
    themeQuery: markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        name
        projects
        image {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    projectsQuery: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "project-page" }
          name: { in: $projects }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            name
            description
            image {
              id
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid_tracedSVG
            } } }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
